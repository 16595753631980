import * as Sentry from "@sentry/browser";

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (dsn) {
  console.debug("Setting up Sentry. DSN is", dsn);
  Sentry.init({
    dsn,
    tracesSampleRate: 0.1,
    environment: import.meta.env.MODE,
  });
} else {
  console.debug("Not setting up Sentry. No DSN provided.");
}
